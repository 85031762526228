<template>
    <div class="body-container">
        <BannerSection title="Préstamos sin buró: ¡Obtén financiación <br class='d-none d-lg-block'/>con tu auto como garantía!"
                       subtitle="Rápido, seguro y sin dejar de usar tu auto" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>
                        ¿Te preocupa estar en Buró de Crédito y no sabes cómo obtener un préstamo? Si estás en una emergencia financiera, hay una solución que te permitirá acceder al dinero que necesitas hoy mismo.Sigue leyendo y descubre cómo los <b>préstamos sin importar Buró de Crédito</b> pueden ser tu mejor aliado.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Me urge un préstamo para hoy pero estoy en Buró</h3>
                    </span>
                    <p>
                        Cuando el tiempo apremia y necesitas dinero con urgencia, estar en Buró de Crédito no tiene por qué ser un obstáculo. Con nuestra alternativa, puedes utilizar tu coche como garantía sin perder el control sobre él.
                    </p>
                    <p>
                        Imagina poder solventar esa emergencia financiera y seguir conduciendo tu coche, sin las complicaciones de los procesos bancarios tradicionales.
                    </p>
                    <br>

                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Cómo funcionan los préstamos sin buró?</h3>
                    </span>
                    <p>
                        Solicitar un préstamo con tu coche como garantía es un proceso sencillo, rápido y 100% online. Te explicamos cómo hacerlo paso a paso:
                    </p>
                    <ol>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Información del vehículo
                            </h4>
                            <span>Empieza proporcionando los detalles básicos de tu coche: marca, modelo, año y kilometraje. Esta información es esencial para calcular la oferta de préstamo que mejor se ajuste a tus necesidades.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Oferta inmediata
                            </h4>
                            <span>Con los datos de tu vehículo, recibirás una oferta en tiempo real. Nuestra propuesta es personalizada, asegurando que obtengas el mejor trato sin comprometer tu tranquilidad financiera.</span>
                        </li>

                        <br>

                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Documentación y verificación
                            </h4>
                            <span>Envía los documentos necesarios de forma segura, ya sea por nuestra plataforma online o por WhatsApp. El proceso de verificación es rápido y sin complicaciones, porque sabemos que el tiempo es clave.</span>
                        </li>
                        <br>

                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Recibe tu dinero
                            </h4>
                            <span>Tras la aprobación, el dinero se deposita en tu cuenta bancaria en menos de 24 horas. Así, podrás contar con los fondos de inmediato para cubrir cualquier imprevisto.</span>
                        </li>


                    </ol>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Beneficios de elegirnos
                        </h3>
                       
                    </span>
                    <ul>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Aceptamos a personas incluso en Buró de Crédito
                            </h3>
                            <span>Nos enfocamos en soluciones inclusivas. Tu historial crediticio no será un problema: tu coche es tu crédito.</span>
                        </li>

                        <br>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Proceso 100% en línea, desde la solicitud hasta la aprobación
                            </h3>
                            <span>Desde la solicitud hasta la aprobación, puedes realizar todo sin salir de casa. Nuestra plataforma está diseñada para hacer cada paso rápido y sencillo.</span>
                        </li>
                        <br>

                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Sigue conduciendo tu coche
                            </h3>
                            <span>A diferencia de los empeños tradicionales, no necesitas dejar tu coche. Podrás usarlo con normalidad mientras esté como garantía.</span>
                        </li>
                        <br>

                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Flexibilidad en los pagos
                            </h3>
                            <span>Ofrecemos pagos anticipados sin penalización, diferentes métodos de pago y la posibilidad de elegir la fecha de pago que mejor te convenga.</span>
                        </li>

                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Preguntas frecuentes (FAQs)</h3>
                    </span>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Qué documentos necesito para solicitar el préstamo?</h4>
                        <p style="font-size: 17px;">Necesitarás enviar tu INE, comprobante de ingresos, tarjeta de circulación y fotos de tu coche. Todo se realiza de forma digital para tu comodidad.</p>
                    </div>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Qué pasa si no puedo pagar el préstamo?</h4>
                        <p style="font-size: 17px;">Si tienes dificultades para cumplir con los pagos, ofrecemos soluciones como la venta del coche para saldar la deuda, devolviéndote cualquier excedente.</p>
                    </div>
                    <br>
                    <div>
                        <h4 style="font-size: 20px; font-style: italic;">- ¿Puedo solicitar un préstamo si mi coche tiene varios años?</h4>
                        <p style="font-size: 17px;">Sí, siempre que tu vehículo esté en buen estado y cumpla con los requisitos básicos.
                            <br>
                            <br>
                            Cuando piensas "me urge un préstamo para hoy pero estoy en buró", la solución está más cerca de lo que imaginas. Utilizar tu coche como garantía es una forma rápida y segura de obtener financiación, sin importar tu historial crediticio. 
                            <br>
                            <br>
                            Con un proceso ágil, flexibilidad en los pagos y la tranquilidad de seguir usando tu coche, esta opción se convierte en el respaldo financiero ideal para cualquier emergencia. 
                            <br>
                            <br>
                            ¡Solicita tu préstamo ahora y resuelve tus necesidades de forma eficiente!
                        </p>
                    </div>
                    <br>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosSinBuro',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos sin Buró| Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Si necesitas un préstamo y tienes mal buró, descubre nuestro préstamo de Ibancar. Si tienes coche puede servirte como aval para conseguir un préstamo sin buró.'
                    },
                    {
                        name: 'keywords',
                        content: 'me urge un prestamo para hoy pero estoy en buro - préstamos sin importar buro de credito'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>